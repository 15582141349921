<template>
  <div class="container">
    <div
      v-for="val in set_comments"
      :key="val.id"
      id="comennt_box"
      style="padding-bottom: 20px"
    >
      <div
        v-if="val.type == 'q'"
        style="text-align: left; display: flex; align-items: flex-end"
      >
        <div style="float: left">
          <img src="@/assets/men.jpeg" class="ai-char" v-if="sex == 1" />
          <img src="@/assets/women.png" class="ai-char" v-else />
        </div>
        <div class="ai-comment">
          <div v-for="item in val.question" :key="item.id">
            <p style="white-space: pre-wrap; font-size: 15px; font-weight: 700">
              {{ item.question }}
            </p>
          </div>
          <div v-if="val.is_anser">
            <div v-for="item in val.items" :key="item.id">
              <div class="item-inbox">
                {{ item.anser }}
              </div>

              <div v-show="item.link_type == 1">
                <router-link :to="item.link_url">{{
                  item.link_word
                }}</router-link>
              </div>
              <div v-show="item.link_type == 2">
                <a :href="item.link_url" target="_blank">{{
                  item.link_word
                }}</a>
              </div>

              <div v-show="item.img_url != ''">
                <img :src="item.img_url" style="width: 100%" />
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="item in val.items" :key="item.id">
              <div style="padding: 3px 0">
                <div class="item-box">
                  <a @click="setQuestion(item.id, item.items)" style="cursor: pointer;"
                    >{{ item.items }}
                    <div v-show="item.anser != ''" class="item-inbox">
                      {{ item.anser | omittedText }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-show="val.id > 1" style="padding:5px 0;margin:10px 0;s">
            <a style="cursor: pointer;" @click="setQuestion(qa_back, '戻る')" class="anser-btn">戻る</a>
            <a style="cursor: pointer;" @click="solve()" class="anser-btn">解決しました</a>
            <a style="cursor: pointer;" @click="nonSolve()" class="anser-btn">解決しません</a>
          </div>
        </div>
      </div>
      <div v-else style="text-align: right">
        <div class="anser-box">
          {{ val.text }}
        </div>
      </div>
    </div>
    <div v-show="solve_type == 1">
      <div style="text-align: right">
        <div class="anser-box">
          解決しました
        </div>
      </div>
      <div style="text-align: left; display: flex; align-items: flex-end">
        <div style="float: left">
          <img src="@/assets/men.jpeg" class="ai-char" v-if="sex == 1" />
          <img src="@/assets/women.png" class="ai-char" v-else />
        </div>
        <div class="ai-comment">
          <div >
            <p style="white-space: pre-wrap; font-size: 15px; font-weight: 700">この度はお問い合わせいただきありがとうございました！</p>
          </div>
        </div>
      </div>
    </div>
    <div v-show="solve_type == 2">
      <div style="text-align: right">
        <div class="anser-box">
          解決しません
        </div>
      </div>
      <div style="text-align: left; display: flex; align-items: flex-end">
        <div style="float: left">
          <img src="@/assets/men.jpeg" class="ai-char" v-if="sex == 1" />
          <img src="@/assets/women.png" class="ai-char" v-else />
        </div>
        <div class="ai-comment">
          <div >
            <p style="white-space: pre-wrap; font-size: 15px; font-weight: 700">こちらからお問合せください</p>
          </div>
          <div style="text-align: center; padding-bottom: 20px">
            <a @click="mailTo()" style="cursor: pointer;">メールを開く</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ai-char {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.anser-box {
  background: #555555;
  border-radius: 15px 15px 0px 15px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  line-height: 1.4;
  margin: 10px 0px;
  padding: 10px;
  text-align: left;
  display: inline-block;
}

.ai-comment {
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px;
  width: auto;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #fff;
  border-radius: 15px 15px 15px 0;
}

.item-box {
  background: #eeeeee;
  border-radius: 4px;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  margin: 0px 0px;
  padding: 10px;
  text-align: left;
  width: auto;
  max-width: 100%;
  justify-content: center;
  display: inline-block;
}
.item-inbox {
  font-size: 12px;
  color: #868686;
  border-top: 1px solid #ddd;
  padding-top: 5px;
}
.anser-btn {
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
  color: #333;
  margin: 0 5px;
  font-size: 11px;
}
</style>

<script>
export default {
  name: "noticeindex",
  data: function () {
    return {
      client: this.$route.query.client,
      uid: this.$route.query.uid,
      access_token: this.$route.query.token,
      men_items: [
        {
          id: 1,
          owner_id: 1,
          items: "",
          question: "どのようなことにお困りでしょうか？",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 2,
          owner_id: 1,
          items: "会員登録について",
          question: "会員登録について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 3,
          owner_id: 2,
          items: "本人確認について",
          question: "本人確認について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 4,
          owner_id: 3,
          items: "年齢確認書類は、どの書類を提出すればいいですか？",
          question: "年齢確認書類は、どの書類を提出すればいいですか？",
          anser:
            "年齢確認書類は運転免許証、パスポート、保険証、マイナンバーカードで確認致しております。（学生証不可）\n自社AIシステムにて確認しているため、年齢確認書類全体が確認出来るように、ピントを合わせてぼやけないように撮影をお願いします。\nまたモザイクや塗りつぶし、隠されている部分があると正式な年齢確認書類として認められませんのでご注意ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 5,
          owner_id: 3,
          items: "年齢確認書類は提出後、どのくらいで確認されますか？",
          question: "年齢確認書類は提出後、どのくらいで確認されますか？",
          anser:
            "年齢確認書類提出後、6時間から最大24時間以内に確認が完了します。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 6,
          owner_id: 3,
          items: "18歳の高校生ですが性癖マッチンを利用できますか？",
          question: "18歳の高校生ですが性癖マッチンを利用できますか？",
          anser:
            "18歳の高校生（中退含む）の場合は性癖マッチンに登録することができません。\n18歳を迎えた次の4月2日以降にご登録お待ちしています。\n\n（例）2022年3月に高校卒業される18歳の場合は、2022年4月より登録可能になります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 7,
          owner_id: 2,
          items: "ユーザー登録について",
          question:
            "ユーザー登録について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 8,
          owner_id: 7,
          items: "新規登録ページから登録しましたが、認証メールが届きません。",
          question:
            "新規登録ページから登録しましたが、認証メールが届きません。",
          anser:
            "キャリアドメインのメールアドレスは、高確率で文字化けの発生やメールが届かない可能性があります。\nGmailやYahoo!メールなどのフリーメールアドレスでの登録をおすすめします。\nすでにキャリアドメインのメールアドレスで新規登録をし、メール認証ができない方はお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 9,
          owner_id: 7,
          items: "退会したけど、再登録したいです。",
          question: "退会したけど、再登録したいです。",
          anser:
            "マイページの決済情報より再度月額プランを選択して、決済をしてください。\n過去に利用したことがある方は新規登録ではなく、マイページにログインし決済情報より再度月額プランを選択して決済をしてください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 10,
          owner_id: 2,
          items: "ログインについて",
          question: "ログインについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 11,
          owner_id: 10,
          items: "マイページのパスワードを忘れてログインできなくなりました。",
          question:
            "マイページのパスワードを忘れてログインできなくなりました。",
          anser:
            "パスワード再設定ページより、ご登録のメールアドレスを入力しパスワードを再度設定してください。",
          link_url: "/forget_password",
          link_word: "パスワード再設定ページ",
          img_url: "",
          img_word: "",
        },
        {
          id: 12,
          owner_id: 1,
          items: "サービスについて",
          question: "サービスについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 13,
          owner_id: 12,
          items: "性癖マッチンについて",
          question:
            "性癖マッチンについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 14,
          owner_id: 13,
          items: "性癖ちゃんについて",
          question:
            "性癖ちゃんについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 15,
          owner_id: 14,
          items: "性癖ちゃんの中の人は男性ですか女性ですか？",
          question: "性癖ちゃんの中の人は男性ですか女性ですか？",
          anser:
            "99%女性です。1％で社長、エンジニア、デザイナーがログインすることがあります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 16,
          owner_id: 14,
          items:
            "公式Twitterアカウント(@yamada_hona)をメンションしたのにRTされません。",
          question:
            "公式Twitterアカウント(@yamada_hona)をメンションしたのにRTされません。",
          anser:
            "全て手動で行っておりますので、全てのメンションに気付けない場合があります。 また、競合サービスの言及をされてる方はRTはしておりません。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 17,
          owner_id: 14,
          items:
            "プロフィール登録ページでDM解放・公式Twitterアカウントのフォローのお願いをチェックしましたが、登録するとチェックが外れてしまいます。",
          question:
            "プロフィール登録ページでDM解放・公式Twitterアカウントのフォローのお願いをチェックしましたが、登録するとチェックが外れてしまいます。",
          anser:
            "プロフィール登録ボタンを押した後、チェックが外れる仕様になっています。 再度プロフィールを変更される時に、都度チェックをお願いします。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 18,
          owner_id: 13,
          items: "マッチンについて",
          question: "マッチンについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 19,
          owner_id: 18,
          items: "地方在住ですがマッチンできますか？",
          question: "地方在住ですがマッチンできますか？",
          anser:
            "地方は首都圏に比べて登録者が少ないですが、その分しっかりとTwitterアカウントを作り込めば他の男性よりも高確率でマッチンできます。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 20,
          owner_id: 18,
          items: "○○県の登録者はいますか？",
          question: "○○県の登録者はいますか？",
          anser:
            "47都道府県全てに登録者がいます。ここまでの情報しかお伝えしていません。その他の情報は、こちらのマッチン白書をご確認ください。",
          link_url: "https://itonami.design/media/seihekimatching_hakusho/",
          link_word: "マッチン白書",
          img_url: "",
          img_word: "",
        },
        {
          id: 21,
          owner_id: 18,
          items: "他のマッチングアプリと違う点はありますか？",
          question: "他のマッチングアプリと違う点はありますか？",
          anser:
            "地方は都会に比べて登録者が少ないですが、その分しっかりとTwitterを作り込めば他の男性よりも高確率でマッチングできるようになります。\n\n人の手によるきめ細かいフォローアップ体制を作り、安心安全な出会いを提供しています。\n\n出会った男性のレビューを書くことで、危ない男性を排除する体制を整えています。\n\n",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 22,
          owner_id: 18,
          items: "登録後は何をすればいいですか？",
          question: "登録後は何をすればいいですか？",
          anser:
            "Twitterアカウントを作り込みましょう。大手裏垢男子を参考に、自分というキャラクターを確立させましょう。ランクを上げるとレコメンドされる可能性が上がるので、下記リンクを参考にランクが上がるようTwitterアカウントを育てていきましょう。",
          link_url: "https://service.seiheki-matching.jp/men_rank",
          link_word: "ランクについて",
          img_url: "",
          img_word: "",
        },
        {
          id: 23,
          owner_id: 13,
          items: "レコメンドについて",
          question:
            "レコメンドについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 24,
          owner_id: 23,
          items: "マイページから確認できる、レコメンド数って何ですか？",
          question: "マイページから確認できる、レコメンド数って何ですか？",
          anser:
            "運営が、女性にあなたをおすすめした数です。女性がDMでやり取りをしたいと思ったらマッチンになります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 25,
          owner_id: 23,
          items: "レコメンドされません",
          question: "レコメンドされません",
          anser:
            "女性の登録している性癖や条件、その他好みの体型などが一致しないとレコメンド対象となりません。\nまた、女性からすると返信率高い男性の方が好まれます。ランクが高くてもDMの返信率が低い男性はレコメンドされにくくなります。（女性からの初回DMテンプレートを送られてきて24時間以内にお返事がないとお返事なしとみなされます。）\n万が一悪質な行為が確認できた、もしくは利用規約違反を行った場合、レコメンドの停止、もしくは退会の措置を取らせていただく場合ございます。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 26,
          owner_id: 23,
          items:
            "マイページではマッチン数が1件増えていますが、女性からDMが来ません。",
          question:
            "マイページではマッチン数が1件増えていますが、女性からDMが来ません。",
          anser:
            "女性がDMを送っていない、または女性のTwitterアカウントが凍結や削除されている可能性があります。マッチンした女性のTwitterアカウントなどの詳細については回答できません。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 27,
          owner_id: 13,
          items:
            "全然マッチンしないのですが、どうすればマッチンしてくれますか？",
          question:
            "全然マッチンしないのですが、どうすればマッチンしてくれますか？",
          anser: "マッチンするかどうかは女性次第です。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 28,
          owner_id: 13,
          items: "マッチンした女性に金銭を要求されました。",
          question: "マッチンした女性に金銭を要求されました。",
          anser:
            "金銭のやり取りはルール違反です。運営まで報告をお願い致します。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 29,
          owner_id: 12,
          items: "ボードについて",
          question: "ボードについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 30,
          owner_id: 29,
          items: "ボードって何ですか？",
          question: "ボードって何ですか？",
          anser:
            "自社ドメインの掲示板です。匿名で投稿できるので、色々な情報交換をしてみてください。新しい機能については下記リンクのページで詳しく説明しています！\n※投稿が男性か女性かは色分けして表示されるので性別だけはほかのユーザーにわかるようにしています。",
          link_url: "https://note.com/yamada_hona/n/n9519780c5b42",
          link_word: "ボードについて",
          img_url: "",
          img_word: "",
        },
        {
          id: 31,
          owner_id: 29,
          items: "ボードの書き込みができません。",
          question: "ボードの書き込みができません。",
          anser: "男性は【女性専用ボード】には書き込みできません。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 32,
          owner_id: 12,
          items: "レビューについて",
          question: "レビューについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 33,
          owner_id: 32,
          items: "みんなのレビューって何ですか？",
          question: "みんなのレビューって何ですか？",
          anser:
            "マッチンした男性と会った後に女性が入力しているレビューです。入力されたレビューは運営にてチェック後、みんなのレビューへ公開しています。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 34,
          owner_id: 32,
          items: "女性からのレビューはどのように反映されますか？",
          question: "女性からのレビューはどのように反映されますか？",
          anser:
            "女性が投稿したレビューの項目ごとの評価を点数化し、一定の点数を超えた場合は高評価としてランクに反映されます。\n女性は複数回男性に会った場合、複数回のレビューを投稿することができますが、同一の女性からのレビューの場合、一件のレビューとみなし、平均点数がランクに反映されます。\n男性自身の個別のレビューは開示していません。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 35,
          owner_id: 32,
          items: "自分宛のレビュー件数・レビュー内容は閲覧出来ますか？",
          question: "自分宛のレビュー件数・レビュー内容は閲覧出来ますか？",
          anser:
            "レビューの件数・内容は閲覧出来ません。匿名で投稿されたみんなのレビューにて閲覧可能です。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 36,
          owner_id: 32,
          items:
            "女性にレビューを書いてもらいたいがどうお願いしていいかわかりません。",
          question:
            "女性にレビューを書いてもらいたいがどうお願いしていいかわかりません。",
          anser:
            "女性からレビューの強要の報告が度々上がっています。レビューを無理にお願いすると女性はレビューを書きたくないと思うため、レビューを書きたいと思えるような時間を女性と過ごしてください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 37,
          owner_id: 12,
          items: "ランクについて",
          question: "ランクについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 38,
          owner_id: 37,
          items: "なぜ、このランクなのですか？",
          question: "なぜ、このランクなのですか？",
          anser:
            "ランクの基準は下記リンクのページをご覧ください。週1回のみ、マイページからランク付け申請を行えます。",
          link_url: "https://service.seiheki-matching.jp/men_rank",
          link_word: "ランクについて",
          img_url: "",
          img_word: "",
        },
        {
          id: 39,
          owner_id: 37,
          items:
            "前回ランク申請してから1週間経ちましたがランクの再申請が出来ません。",
          question:
            "前回ランク申請してから1週間経ちましたがランクの再申請が出来ません。",
          anser:
            "ランク付けを再申請してから1週間ではなく、運営がランク付けをしてから1週間経つと再度ランク付け申請が可能になります。ランク申請ページに表示される申請可能日時を過ぎても、ランク付け再申請出来ない場合は運営までお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 40,
          owner_id: 37,
          items: "ランクが下がることはありますか？",
          question: "ランクが下がることはありますか？",
          anser:
            "あります。ランク付け申請をされた時のアカウントを確認して、その時のツイート内容などを見て判断しています。\nまた、何らかの理由でスタッフが男性のアカウントを確認した際に、現状のランクとツイート内容などに相違がある場合も、改めてランク付けをさせていただきます。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 41,
          owner_id: 1,
          items: "Twitterアカウントについて",
          question:
            "Twitterアカウントについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 42,
          owner_id: 41,
          items: "アカウントについて",
          question:
            "アカウントについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 43,
          owner_id: 42,
          items:
            "Twitterアカウントを変更したいのですが、ランクは引き継がれますか？",
          question:
            "Twitterアカウントを変更したいのですが、ランクは引き継がれますか？",
          anser:
            "Twitterアカウントを変更されると、ランクは引き継がれません。変更後のTwitterアカウントを確認し、運営がランク付けをします。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 44,
          owner_id: 42,
          items:
            "マイページよりプロフィール登録をする時に、TwitterIDを大文字で入力しても小文字になってしまい更新できません。",
          question:
            "マイページよりプロフィール登録をする時に、TwitterIDを大文字で入力しても小文字になってしまい更新できません。",
          anser:
            "大文字、小文字の区別はしておりません。大文字でも小文字でも、入力されたご自身のTwitterIDのスペルが正しければTwitterアカウントを検出できます。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 45,
          owner_id: 41,
          items: "Twitterの設定について",
          question:
            "Twitterの設定について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 46,
          owner_id: 45,
          items:
            "なぜクオリティーフィルターをOFFにしなければいけないのですか？",
          question:
            "なぜクオリティーフィルターをOFFにしなければいけないのですか？",
          anser:
            "クオリティフィルターをONにすると、Twitterが「不適切なメッセージ」だと判断したDMはメッセージリクエストの一覧に表示されません。女性がDMを送ったのに男性側からは届いていない状態になります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 47,
          owner_id: 45,
          items: "クオリティフィルターの解除の仕方を教えてください。",
          question: "クオリティフィルターの解除の仕方を教えてください",
          anser:
            "「設定とプライバシー」→「通知」→「フィルター」→「クオリティフィルター」をOFFにします。",
          link_url: "",
          link_word: "",
          img_url:
            "https://storage.googleapis.com/studio-design-asset-files/projects/V5a76elLqR/s-750x493_v-fs_webp_cd420f48-de1a-4d51-bc47-1b37a3940a39_small.webp",
          img_word: "",
        },
        {
          id: 48,
          owner_id: 45,
          items:
            "ツイートを確認すると「センシティブな内容が含まれている可能性があります」と表示されます。",
          question:
            "ツイートを確認すると「センシティブな内容が含まれている可能性があります」と表示されます。",
          anser:
            "成人向けコンテンツは、「センシティブな内容が含まれている可能性があります」と表示されます。解除するには、ブラウザでツイッターにログイン→「設定とプライバシー」→「プライバシーと安全」→「表示するコンテンツ」→「センシティブな内容を含む可能性のあるメディアを表示する」にチェックを入れる\n\n以上の手順で設定してください。",
          link_url: "",
          link_word: "",
          img_url:
            "https://storage.googleapis.com/studio-design-asset-files/projects/V5a76elLqR/s-750x688_v-fs_webp_92a14125-4965-45f2-a472-a75d0a55e844_small.webp",
          img_word: "",
        },
        {
          id: 49,
          owner_id: 41,
          items:
            "Twitterアカウントが凍結しました。どうすれば性癖マッチンの利用を再開できますか?",
          question:
            "Twitterアカウントが凍結しました。どうすれば性癖マッチンの利用を再開できますか?",
          anser:
            "マイページのプロフィール登録画面で、凍結していないTwitterアカウントのIDとTwitterアカウント名を入力してください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 50,
          owner_id: 41,
          items:
            "Twitterアカウントがシャドウバンされました。レコメンドに影響はありますか？",
          question:
            "Twitterアカウントがシャドウバンされました。レコメンドに影響はありますか？",
          anser:
            "レコメンドに影響はありません。 しかしシャドウバンされると、メンションやリプライをされても確認することができません。タイムラインでツイートを確認することは可能です。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 51,
          owner_id: 1,
          items: "退会について",
          question: "退会について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 52,
          owner_id: 51,
          items: "性癖マッチンを退会したいです。",
          question: "性癖マッチンを退会したいです。",
          anser:
            "退会ページよりご自身の決済ID、パスワードを入力し退会手続きを行ってください。決済会社より退会のメールが届いたら正式に退会完了となります。",
          link_url: "/payment_cancellation",
          link_word: "退会ページ",
          img_url: "",
          img_word: "",
        },
        {
          id: 53,
          owner_id: 51,
          items: "ユーザー情報について",
          question:
            "ユーザー情報について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 54,
          owner_id: 53,
          items:
            "一度退会して再登録した場合、レコメンドやマッチン数、ランクは引き継がれますか？",
          question:
            "一度退会して再登録した場合、レコメンドやマッチン数、ランクは引き継がれますか？",
          anser:
            "レコメンド数やマッチン数は一度退会されても、再開時に引き継ぐ事ができます。ランクは再登録時のTwitterアカウントの状態にてランク付けを行います。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 55,
          owner_id: 53,
          items: "決済IDとマイページログインIDは別ですか？",
          question: "決済IDとマイページログインIDは別ですか？",
          anser:
            "マイページログインIDは登録されているメールアドレス、決済IDはマイページの決済情報ページにて確認できます。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 56,
          owner_id: 51,
          items: "退会したが、退会を取り消ししたいです。",
          question: "退会したが、退会を取り消ししたいです。",
          anser:
            "退会を取り消すことはできません。サービス利用再開時はマイページにログイン後、決済情報より再度決済手続きとなります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 57,
          owner_id: 51,
          items: "退会トラブルについて",
          question:
            "退会トラブルについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 58,
          owner_id: 57,
          items:
            "退会ページのURLをタップすると毎回ログイン画面に戻ってしまいます。",
          question:
            "退会ページのURLをタップすると毎回ログイン画面に戻ってしまいます。",
          anser:
            "会員情報確認のため、マイページログイン後男性用【よくある質問と答え】より退会ページに進みます。「決済退会手続き」ページより決済ID、パスワードの入力後、決済会社の退会ページにて手続きとなります。もし決済会社の退会ページにログインできない場合は、ブラウザのプライベートモードを解除して再度お試しください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 59,
          owner_id: 57,
          items:
            "退会ページで決済用のIDパスワードを入力しても、「ユーザーではありません」と表示されてしまいます。",
          question:
            "退会ページで決済用のIDパスワードを入力しても、「ユーザーではありません」と表示されてしまいます。",
          anser:
            "決済ID、パスワードを半角英数字にて手入力をお願いします。手入力してもエラー画面が出る場合は、エラー画面のスクリーンショットを撮っていただき運営までお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 60,
          owner_id: 57,
          items: "退会したのに決済が続いています。",
          question: "退会したのに決済が続いています。",
          anser:
            "退会手続き完了時に登録のメールアドレスに届いた【性癖マッチン決済登録解除のお知らせ】のメールの、ご登録のメールアドレス、受信日時がわかるようスクリーンショットを撮ってください。\nスクリーンショットを添付し運営までお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 61,
          owner_id: 57,
          items:
            "退会手続きしていないのに、退会手続き完了のメールが届きました。",
          question:
            "退会手続きしていないのに、退会手続き完了のメールが届きました。",
          anser:
            "決済会社にて継続決済を試みましたが、正常に決済が行えなかったため退会扱いとなります。決済失敗の原因はこちらではお調べすることができません。クレジットカードの有効期限、ご利用金額の上限の確認、および性癖マッチンは海外決済システム使用のため、ご登録のクレジットカードが海外利用可能かご確認ください。それでも原因不明の場合はご利用のクレジットカード会社へお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 62,
          owner_id: 1,
          items: "決済について",
          question: "決済について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 63,
          owner_id: 62,
          items: "決済方法を知りたい",
          question: "決済方法を知りたい",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 64,
          owner_id: 63,
          items: "決済は何日ごとに発生しますか？",
          question: "決済は何日ごとに発生しますか？",
          anser:
            "初回決済日を起算日として31日後に2回目の決済、以降30日サイクルとなります。\n\n例）1/1に登録（初回決済）→1/31（2回目決済）、3/1（3回目決済）",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 65,
          owner_id: 63,
          items: "クレジット以外の決済方法はありますか？",
          question: "クレジット以外の決済方法はありますか？",
          anser: "クレジット決済以外の決済方法はございません。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 66,
          owner_id: 63,
          items: "デビットカードでの決済は可能ですか？",
          question: "デビットカードでの決済は可能ですか？",
          anser:
            "VISA,JCB,MasterCardのマークがついているデビットカードはご利用いただけます。ただしカード会社やカードの利用状況によってはご利用いただけない可能性もあります。登録ができない場合はご利用のクレジットカード会社へお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 67,
          owner_id: 63,
          items:
            "性癖マッチンの月会費は、クレジット明細にはどのように表記されますか？",
          question:
            "性癖マッチンの月会費は、クレジット明細にはどのように表記されますか？",
          anser:
            "bwl0366304080 もしくは、BW0366304080と表記されます。ただし、ご利用のカード会社によりBWの後に決済銀行の国名（KOREA等）と表記される場合があります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 68,
          owner_id: 62,
          items: "決済の変更について",
          question:
            "決済の変更について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 69,
          owner_id: 68,
          items:
            "登録しているクレジットカードを変更したい場合はどうすればいいですか？",
          question:
            "登録しているクレジットカードを変更したい場合はどうすればいいですか？",
          anser: "クレジット情報変更ページより変更手続きを行ってください。",
          link_url: "/change_card",
          link_word: "クレジット情報変更",
          img_url: "",
          img_word: "",
        },
        {
          id: 70,
          owner_id: 68,
          items: "決済のプランを変更したいです。",
          question: "決済のプランを変更したいです。",
          anser:
            "次回決済日前に一度退会し、マイページ決済情報より再度新しいプランで決済登録の手続きを行ってください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 71,
          owner_id: 68,
          items:
            "決済登録時の電話番号、メールアドレスを変更したい場合はどうすればいいですか？",
          question:
            "決済登録時の電話番号、メールアドレスを変更したい場合はどうすればいいですか？",
          anser:
            "現在登録されているメールアドレスと変更したいメールアドレスを運営までお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 72,
          owner_id: 62,
          items: "決済トラブルについて",
          question:
            "決済トラブルについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 73,
          owner_id: 72,
          items: "決済登録時のSMS認証が届きません。",
          question: "決済登録時のSMS認証が届きません。",
          anser:
            "決済画面に入力された電話番号が正しいか、受信拒否設定、電波状況、メモリの容量をご確認ください。時間をおいてもSMS認証が届かない場合は、ご使用デバイスを再起動をして再度お試しください。SMS認証が全く届かない場合は決済会社マックスコネクトへお問い合わせください。\nまた、海外配信網にてSMSの送信を行っているため、”海外SMSを拒否する”という設定になっていないかご確認ください。\n各キャリアのメニュー（DocomoやSoftbankのメニュー）から設定できます。\n\nマックスコネクト■クレジットカード サポートセンター 　\nTel：03-6826-5621(平日：09:30-18:30) 　\nTel：+65-6829-2340(平日：09:00-18:00)\n■お問い合わせ先メールアドレス info@max-c-e.com",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 74,
          owner_id: 72,
          items: "決済を行いましたが、メールが届きません。",
          question: "決済を行いましたが、メールが届きません。",
          anser:
            "決済が発生・失敗・停止した場合場合に、info@max-c-e.comからメールが送信されます。決済によって正常に性癖マッチンをご利用いただけている場合、性癖マッチンの退会手続きが正常に完了した場合、info@itonami.designからメールが送信されます。info@max-c-e.com と info@itonami.design、それぞれのアドレスから受信できるようドメイン設定をしてください。メールが届かない場合は決済会社マックスコネクトへお問い合わせください。\n\nTel：03-6826-5621(平日：09:30-18:30) \nTel：+65-6829-2340(平日：09:00-18:00)  \n■お問い合わせ先メールアドレス info@max-c-e.com",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 75,
          owner_id: 72,
          items:
            "決済を登録したのに、マイページでは「決済が確認できていません。」と表記されています。",
          question:
            "決済を登録したのに、マイページでは「決済が確認できていません。」と表記されています。",
          anser:
            "性癖マッチンに登録したメールアドレスと、決済登録したメールアドレスが異なると決済情報が紐づかず、マイページの決済情報に反映されません。性癖マッチンに登録されたメールアドレスと、決済時のメールアドレス、どちらのメールアドレスに統一するかを運営へお知らせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 76,
          owner_id: 72,
          items:
            "レコメンドされないので返金をしてもらいたい、クーリングオフを適応してもらいたいです。",
          question:
            "レコメンドされないので返金をしてもらいたい、クーリングオフを適応してもらいたいです。",
          anser:
            "決済の取り消しは行ないません。 利用規約第10条をご確認ください。",
          link_url: "https://service.seiheki-matching.jp/MEN_userpolicy",
          link_word: "利用規約",
          img_url: "",
          img_word: "",
        },
      ],
      women_items: [
        {
          id: 1,
          owner_id: 1,
          items: "",
          question: "どのようなことにお困りでしょうか？",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 2,
          owner_id: 1,
          items: "会員登録について",
          question: "会員登録について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 3,
          owner_id: 2,
          items: "本人確認について",
          question: "本人確認について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 4,
          owner_id: 3,
          items: "年齢確認書類は、どの書類を提出すればいいですか？",
          question: "年齢確認書類は、どの書類を提出すればいいですか？",
          anser:
            "年齢確認書類は運転免許証、パスポート、保険証、マイナンバーカードで確認致しております。（学生証不可）自社AIシステムにて確認しているため、年齢確認書類全体が確認出来るように、ピントを合わせてぼやけないように撮影をお願いします。またモザイクや塗りつぶし、隠されている部分があると正式な年齢確認書類として認められませんのでご注意ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 5,
          owner_id: 3,
          items: "年齢確認書類は、提出後どのくらいで確認されますか？",
          question: "年齢確認書類は、提出後どのくらいで確認されますか？",
          anser:
            "年齢確認書類提出後、6時間から最大24時間以内に確認が完了します。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 6,
          owner_id: 3,
          items: "18歳の高校生ですが性癖マッチンを利用できますか？",
          question: "18歳の高校生ですが性癖マッチンを利用できますか？",
          anser:
            "18歳の高校生（中退含む）の場合は性癖マッチンへ登録ができません。18歳を迎えた次の4月2日以降にご登録お待ちしています。\n\n（例）2022年3月に高校卒業される18歳の場合は、2022年4月より登録可能になります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 7,
          owner_id: 2,
          items: "マイページのパスワードを忘れてログインできなくなりました。",
          question:
            "マイページのパスワードを忘れてログインできなくなりました。",
          anser:
            "パスワード再設定ページより、ご登録のメールアドレスを入力しパスワードを再度設定してください。",
          link_url: "/forget_password",
          link_word: "パスワード再設定ページ",
          img_url: "",
          img_word: "",
        },
        {
          id: 8,
          owner_id: 2,
          items: "登録のメールアドレスを変更したいです。",
          question: "登録のメールアドレスを変更したいです。",
          anser: "レコメンドが送られるTwitterアカウントまでご連絡ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 9,
          owner_id: 2,
          items: "新規登録ページから登録しましたが、認証メールが届きません。",
          question:
            "新規登録ページから登録しましたが、認証メールが届きません。",
          anser:
            "キャリアドメインのメールアドレスは、高確率で文字化けの発生やメールが届かない可能性があります。GmailやYahoo!メールなどのフリーメールアドレスでの登録をおすすめします。すでにキャリアドメインのメールアドレスで新規登録をし、メール認証ができない方は@yamada_honaまでお問い合わせください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 10,
          owner_id: 1,
          items: "サービスについて",
          question: "サービスについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 11,
          owner_id: 10,
          items: "性癖マッチンについて",
          question:
            "性癖マッチンについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 12,
          owner_id: 11,
          items: "登録しているユーザーについて",
          question:
            "登録しているユーザーについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 13,
          owner_id: 12,
          items: "○○県の登録者はいますか？",
          question: "○○県の登録者はいますか？",
          anser:
            "47都道府県全てに登録者がいます。ここまでの情報しかお伝えしていません。その他の情報は、マッチン白書をご確認ください。",
          link_url: "https://itonami.design/media/seihekimatching_hakusho/",
          link_word: "マッチン白書",
          img_url: "",
          img_word: "",
        },
        {
          id: 14,
          owner_id: 12,
          items: "男性から最初のDM送ってもらうことはできませんか？",
          question: "男性から最初のDM送ってもらうことはできませんか？",
          anser: "性癖マッチンは女性からやり取りが始まるサービスです。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 15,
          owner_id: 12,
          items: "男性の顔写真は事前に確認できますか？",
          question: "男性の顔写真は事前に確認できますか？",
          anser:
            "登録されている性癖を確認しレコメンドしているので、男性の顔が気になる場合はマッチン後に写真の交換を提案してみてください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 16,
          owner_id: 11,
          items: "他のマッチングアプリと違う点はありますか？",
          question: "他のマッチングアプリと違う点はありますか？",
          anser:
            "■一般的なマッチングアプリ\n・男性から届くいいねを見て、プロフィールと写真を見てマッチング\n・趣味や仕事の話を中心に徐々に仲良くなる\n・結婚相手や彼氏候補など、私だけの彼を探すためのサービス\n\n■性癖マッチン\n・男性からしつこいアプローチが来ない。\n・間に運営が入るので、安心安全に遊ぶことができる。\n・目的がはっきりしているので、より親密な関係をすぐに作ることができる。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 17,
          owner_id: 11,
          items: "登録後の流れを教えて下さい。",
          question: "登録後の流れを教えて下さい。",
          anser:
            "1.随時男性をレコメンド（紹介）します。\nお送りしたURLからマイページにログインして男性のプロフィールを確認してください。\n\n2.いいなと思ったら'男性に連絡する'を選択（マッチン）\n\n3.微妙と思ったら'男性に連絡しない'を選択（ノーマッチン）\n※ノーマッチンの場合は理由も選択してください。\n\n4.マッチンだった場合、マイページの男性ページからテンプレートをコピぺして男性にDMで送ってください。\nテンプレートは「男性に送る自己紹介メッセージをコピーする」をタップすると自動でコピーされます。\n\n5.男性から返事が返ってきたらマイページから'返信あり'を選択。\n\n6.24時間経ってもお返事がない場合マイページから'返信なし'を選択。\n\n7.男性とお会いした後にレビューの入力をお願いします。\n※レビューはマイページ内のメニュー【レコメンド】やDMでお送りした男性のURLから書くことが出来ます。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 18,
          owner_id: 11,
          items: "レコメンドについて",
          question:
            "レコメンドについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 19,
          owner_id: 18,
          items: "何回レコメンドしてくれますか？",
          question: "何回レコメンドしてくれますか？",
          anser:
            "登録している間は、随時レコメンドさせて頂きます。レコメンドが微妙だなっていう時はマイページから'男性に連絡しない'を選択してください。基本的に1日1回、マッチンするまで何度でもレコメンドします。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 20,
          owner_id: 18,
          items: "レコメンドの不安について",
          question: "レコメンドで気になることはありますか？",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 21,
          owner_id: 20,
          items: "マッチンしたら必ず会わなきゃいけないですか？",
          question: "マッチンしたら必ず会わなきゃいけないですか？",
          anser:
            "マッチン後のDMのやり取りは自由です。お会いした時に何をするかも自由なので、男性とお話しして決めてください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 22,
          owner_id: 20,
          items: "体型に自信がないけどマッチンできますか？",
          question: "体型に自信がないけどマッチンできますか？",
          anser:
            "男性は、女性に希望する体型を登録しています。体型も考慮してレコメンドしているのでご安心ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 23,
          owner_id: 20,
          items: "自分の年齢が不安です。",
          question: "自分の年齢が不安です。",
          anser:
            "18歳〜50代以上まで、幅広い年齢層の女性が登録しております。\nまた、男性にも希望する女性の年齢を選択いただいてます。体型と同様、年齢も考慮したうえでレコメンドしていますのでご安心ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 24,
          owner_id: 20,
          items: "マッチンにするか、ノーマッチンにするか悩んでいます。",
          question: "マッチンにするか、ノーマッチンにするか悩んでいます。",
          anser:
            "1週間保留できます。1週間マッチン、ノーマッチンのご連絡がなかった場合、ノーマッチンとなりその後マッチンすることができませんのでご注意ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 25,
          owner_id: 18,
          items: "マッチンについて",
          question: "マッチンについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 26,
          owner_id: 25,
          items: "マッチンしたあとについて",
          question: "マッチンしたら・・・",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 27,
          owner_id: 26,
          items: "男性にテンプレを送りましたが返信がありません。",
          question: "男性にテンプレを送りましたが返信がありません。",
          anser:
            "男性の生活リズムを考慮し、24時間はお返事をお待ちいただいています。\n男性から24時間経ってもお返事がない場合は、マイページから'返信なし'を選択してください。\n次の男性をレコメンドさせていただきます。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 28,
          owner_id: 26,
          items: "マッチンにしたけどやっぱりノーマッチンにしたいです。",
          question: "マッチンにしたけどやっぱりノーマッチンにしたいです。",
          anser:
            "マッチン数は男性のマイページに反映されます。マッチン後ノーマッチンに変更されると男性のマッチン数のカウントが減ってしまいます。'男性に連絡する'を選択する前にご判断お願いします。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 29,
          owner_id: 26,
          items:
            "マッチンしてやり取りしたら微妙だったのでノーマッチンに変更したいです。",
          question:
            "マッチンしてやり取りしたら微妙だったのでノーマッチンに変更したいです。",
          anser:
            "マッチン、ノーマッチンは、マッチンとお返事をした時点で決まります。マッチン後、男性からお返事があった場合、マイページのレコメンド一覧の表示ではマッチンのままとなります。マイページのレコメンド一覧にはマッチンと表示されますが、マッチン後のやりとりは自由なので絶対に会わなくてはいけないわけではありません。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 30,
          owner_id: 25,
          items:
            "レコメンドされた男性をノーマッチンにした場合男性にバレますか？",
          question:
            "レコメンドされた男性をノーマッチンにした場合男性にバレますか？",
          anser: "バレないのでご安心ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 31,
          owner_id: 25,
          items: "全然マッチンしないのですが、どうすればマッチンできますか？",
          question:
            "全然マッチンしないのですが、どうすればマッチンできますか？",
          anser:
            "(1)希望年齢の範囲が狭い\n大人の余裕がある男性は20代後半以上に集まっています。対象年齢が狭い、または若すぎるといい男性がいる確率が下がってしまうので少し多めに見積もってみてもいいかもしれません。\n\n(2)プロフィールに要望を書いていない\nプロフィールに書いてある情報をもとにレコメンドを行っているので、書いていない情報を察知することはできません。できるだけ詳しく、要望またはNG項目を書いて頂けると素敵なマッチンができるので、最初のプロフィール登録はお手数ですが頑張って書いてみてください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 32,
          owner_id: 25,
          items:
            "たくさん男性をレコメンドしてもらったけど、なかなかマッチンしなくなってきました。",
          question:
            "たくさん男性をレコメンドしてもらったけど、なかなかマッチンしなくなってきました。",
          anser:
            "プロフィールの見直しをしてみてください。新しい条件でレコメンドできる男性が増える可能性があります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 33,
          owner_id: 18,
          items: "レコメンドを一時停止したいです。",
          question: "レコメンドを一時停止したいです。",
          anser:
            "運営からのレコメンドがお腹いっぱいになったら、レコメンドを一時停止できます。レコメンドが送られるTwitterアカウントへDMで教えて下さい。再開する時もレコメンドが送られるアカウントへDMで教えて下さい。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 34,
          owner_id: 18,
          items: "レコメンドに対しての回答を保留にするとどうなりますか？",
          question: "レコメンドに対しての回答を保留にするとどうなりますか？",
          anser:
            "その男性に連絡するかしないかを決めなくても、保留にしている間は次のレコメンドを受け取ることができます。\n保留できる期間は1週間です。1週間経過すると、男性に連絡することができなくなります。\n'男性に連絡する'を選択していない男性に、自己紹介テンプレートを送ることは禁止しております。\nまた、保留にしている間に男性が退会してしまった場合は男性に連絡することができませんので、ご注意ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 35,
          owner_id: 11,
          items: "ボードについて",
          question: "ボードについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 36,
          owner_id: 35,
          items: "ボードって何ですか？",
          question: "ボードって何ですか？",
          anser:
            "自社ドメインの掲示板です。匿名で投稿できるので、色々な情報交換をしてみてください。新しい機能については下記リンクのページで詳しく説明しています！\n※投稿が男性か女性かは色分けして表示されるので性別だけはほかのユーザーにわかるようにしています。",
          link_url: "https://note.com/yamada_hona/n/n9519780c5b42",
          link_word: "掲示板について",
          img_url: "",
          img_word: "",
        },
        {
          id: 37,
          owner_id: 35,
          items: "ボードの書き込みができません。",
          question: "ボードの書き込みができません。",
          anser: "女性は【男性専用ボード】には書き込みできません。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 38,
          owner_id: 11,
          items: "レビューについて",
          question: "レビューについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 39,
          owner_id: 38,
          items: "みんなのレビューって何ですか？",
          question: "みんなのレビューって何ですか？",
          anser:
            "マッチンした男性と会った後に女性が入力しているレビューです。\n入力されたレビューは運営にてチェック後、みんなのレビューへ公開しています。\n男性よりレビューの強要があった場合は、レコメンドが送られるTwitterアカウントまでご報告ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 40,
          owner_id: 38,
          items: "レビューは絶対に書かなくてはいけないですか？",
          question: "レビューは絶対に書かなくてはいけないですか？",
          anser:
            "絶対に書かなくてはいけないわけではありません。レビュー投稿ページの設問に公開可否についての質問がありますのでお選びください。レビューは男性のランクに反映されるので、男性に会ってエッチした時の素直な感想の入力をお願いいたします。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 41,
          owner_id: 38,
          items:
            "会ってないけどメッセージのやり取りが不快だったのでレビューしたいです。",
          question:
            "会ってないけどメッセージのやり取りが不快だったのでレビューしたいです。",
          anser:
            "レビューは実際にエッチをした場合にのみ投稿してください。\nメッセージのやり取りでトラブルが発生した場合、レコメンドが送られてくるアカウントにご報告ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 42,
          owner_id: 38,
          items: "書いたレビューについて",
          question: "自分が書いたレビューが気になる",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 43,
          owner_id: 42,
          items: "自分が書いたレビューを、相手の男性が見ることはできますか？",
          question:
            "自分が書いたレビューを、相手の男性が見ることはできますか？",
          anser:
            "現在男性が特定できないよう、匿名でみんなのレビューで公開されています。\n公開したくないを選択した場合、みんなのレビューでは公開されませんのでご安心ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 44,
          owner_id: 42,
          items: "男性に、自分が投稿したレビュー内容を教えてもいいですか？",
          question: "男性に、自分が投稿したレビュー内容を教えてもいいですか？",
          anser:
            "トラブル防止のため、男性にレビュー内容を伝えることはご遠慮ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 45,
          owner_id: 38,
          items: "マッチンした男性にレビューを強要されました。",
          question: "マッチンした男性にレビューを強要されました。",
          anser:
            "男性からのレビューの強要はルール違反です。\nレコメンドが送られるTwitterアカウントまでご報告ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 46,
          owner_id: 1,
          items: "Twitterアカウントについて",
          question:
            "Twitterアカウントについて、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 47,
          owner_id: 46,
          items:
            "性癖マッチンに登録しているTwitterアカウントを変更したいです。",
          question:
            "性癖マッチンに登録しているTwitterアカウントを変更したいです。",
          anser:
            "マイページのプロフィールページから変更できます。変更後は、レコメンドが送られてるTwitterアカウントに、新しいTwitterIDを教えてください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 48,
          owner_id: 46,
          items: "Twitterの設定について",
          question:
            "Twitterの設定について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 49,
          owner_id: 48,
          items:
            "なぜクオリティーフィルターをOFFにしなければいけないのですか？",
          question:
            "なぜクオリティーフィルターをOFFにしなければいけないのですか？",
          anser:
            "クオリティフィルターをONにすると、Twitterが「不適切なメッセージ」だと判断したDMはメッセージリクエストの一覧に表示されません。女性がDMを送ったのに男性側からは届いていない状態になります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 50,
          owner_id: 48,
          items: "クオリティフィルターの解除の仕方を教えてください。",
          question: "クオリティフィルターの解除の仕方を教えてください",
          anser:
            "「設定とプライバシー」→「通知」→「フィルター」→「クオリティフィルター」をOFFにします。",
          link_url: "",
          link_word: "",
          img_url:
            "https://storage.googleapis.com/studio-design-asset-files/projects/V5a76elLqR/s-750x493_v-fs_webp_cd420f48-de1a-4d51-bc47-1b37a3940a39_small.webp",
          img_word: "",
        },
        {
          id: 51,
          owner_id: 48,
          items:
            "ツイートを確認すると「センシティブな内容が含まれている可能性があります」と表示されます。",
          question:
            "ツイートを確認すると「センシティブな内容が含まれている可能性があります」と表示されます。",
          anser:
            "成人向けコンテンツは、「センシティブな内容が含まれている可能性があります」と表示されます。解除するには、ブラウザでツイッターにログイン→「設定とプライバシー」→「プライバシーと安全」→「表示するコンテンツ」→「センシティブな内容を含む可能性のあるメディアを表示する」にチェックを入れる\n\n以上の手順で設定してください。",
          link_url: "",
          link_word: "",
          img_url:
            "https://storage.googleapis.com/studio-design-asset-files/projects/V5a76elLqR/s-750x688_v-fs_webp_92a14125-4965-45f2-a472-a75d0a55e844_small.webp",
          img_word: "",
        },
        {
          id: 52,
          owner_id: 1,
          items: "退会について",
          question: "退会について、問い合わせしたい項目を選択してください",
          anser: "",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 53,
          owner_id: 52,
          items: "サービスを退会したいです。",
          question: "サービスを退会したいです。",
          anser:
            "レコメンドが送られているTwitterアカウントへDMでご連絡ください。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
        {
          id: 54,
          owner_id: 52,
          items: "一時停止と退会の違いを教えて下さい",
          question: "一時停止と退会の違いを教えて下さい",
          anser:
            "一時停止は、レコメンド再開の連絡を頂くまでレコメンドをストップすることです。退会は、登録内容とレコメンド履歴の全てを削除し、マイページにログインできなくなります。退会した後、再度レコメンドが送られているTwitterアカウントへレコメンド再開のお願いを送っても、新規登録扱いとなります。過去レコメンドされた男性も再度レコメンド対象となります。",
          link_url: "",
          link_word: "",
          img_url: "",
          img_word: "",
        },
      ],
      qa_back: 1,
      sex: "",
      set_comments: [],
      cnt: 0,
      solve_type:0,
    };
  },
  mounted() {
    this.uid = localStorage.getItem("uid");
    this.client = localStorage.getItem("client");
    this.access_token = localStorage.getItem("access-token");
    this.sex = localStorage.getItem("sex");
    this.setQuestion(0, "");
  },
  filters: {
    omittedText(text) {
      return text.length > 40 ? text.slice(0, 40) + "…" : text;
    },
  },
  updated() {
    var elm = document.documentElement;
    var bottom = elm.scrollHeight - elm.clientHeight;
    window.scroll(0, bottom);
  },
  methods: {
    mailTo() {
      var mail_to = "yamada.hona@gmail.com";
      var mail_from = this.uid;
      var subject = "[性癖マッチン][お問合せ]";
      var body =
        "お名前：%0D%0A%0D%0ATwitterID：%0D%0A%0D%0Aメールアドレス：" +
        mail_from +
        "%0D%0A%0D%0A問い合わせ内容：";
      location.href =
        "mailto:" + mail_to + "?subject=" + subject + "&body=" + body;
    },
    solve(){
      this.solve_type = 1
    },
    nonSolve(){
      this.solve_type = 2
    },
    setQuestion(n, t) {
      this.solve_type = 0

      var back = 1;
      var link_type = 0;
      var i = [];
      var q = [];
      var v = [];
      var is_anser = false;

      const set_question = [];
      const set_item = [];

      if (this.sex == 1) {
        i = this.men_items;
      } else {
        i = this.women_items;
      }

      if (n == 0) {
        // 初回
        q = i.filter((e) => e.id == 1);

        q.forEach(function (element) {
          set_question.push({
            id: element.id,
            owner_id: element.owner_id,
            items: element.items,
            question: element.question,
            anser: element.anser,
            link_url: element.link_url,
            link_word: element.link_word,
            img_url: element.img_url,
          });
          back = element.owner_id;
        });

        this.qa_back = back;

        v = i.filter((e) => e.owner_id == 1);

        v.forEach(function (element) {
          if (element.items != "") {
            set_item.push({
              id: element.id,
              owner_id: element.owner_id,
              items: element.items,
              question: element.question,
              anser: element.anser,
              link_url: element.link_url,
              link_word: element.link_word,
              img_url: element.img_url,
            });
          }
        });

        this.set_comments.push({
          id: this.cnt,
          type: "q",
          question: set_question,
          items: set_item,
          is_anser: is_anser,
        });

        this.cnt++;
      } else {
        // n回目
        q = i.filter((e) => e.id == n);

        q.forEach(function (element) {
          set_question.push({
            id: element.id,
            owner_id: element.owner_id,
            items: element.items,
            question: element.question,
            anser: element.anser,
            link_url: element.link_url,
            link_word: element.link_word,
            img_url: element.img_url,
          });
          back = element.owner_id;
        });

        this.qa_back = back;

        v = i.filter((e) => e.owner_id == n);

        if (v.length == 0) {
          // 回答
          is_anser = true;

          q.forEach(function (element) {
            if (element.link_word == "") {
              link_type = 0;
            } else {
              if (element.link_url.match(/http/) == null) {
                //内部リンク
                link_type = 1;
              } else {
                //外部リンク
                link_type = 2;
              }
            }

            set_item.push({
              id: element.id,
              owner_id: element.owner_id,
              items: element.items,
              question: element.question,
              anser: element.anser,
              link_url: element.link_url,
              link_word: element.link_word,
              img_url: element.img_url,
              link_type: link_type,
            });
          });
        } else {
          is_anser = false;

          v.forEach(function (element) {
            if (element.items != "") {
              set_item.push({
                id: element.id,
                owner_id: element.owner_id,
                items: element.items,
                question: element.question,
                anser: element.anser,
                link_url: element.link_url,
                link_word: element.link_word,
                img_url: element.img_url,
              });
            }
          });
        }

        this.set_comments.push({
          id: this.cnt,
          type: "a",
          text: t,
        });

        this.cnt++;

        this.set_comments.push({
          id: this.cnt,
          type: "q",
          question: set_question,
          items: set_item,
          is_anser: is_anser,
        });

        this.cnt++;
      }
    },
  },
};
</script>