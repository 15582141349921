<template>
  <div class="recommends container content-wrapper" style="padding-top:10px;">
    <div v-if="isLoading" style="padding-top:60px;">
      <Spinner></Spinner>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-sm-6 col-md-3" v-for="rec in data_all.rec" :key="rec.id" v-show="isViewable(rec)">
          <div class="white-card recommend-card">
              <p class="text-left" style="padding-left:10px;"><span style="font-weight: 900;color:#f85b8c;" v-if="rec.status_n==0">New! </span>{{rec.created_at | moment}}</p>
              <div style="padding:0 25px">
              <router-link :to="{ name: 'Profile', query: { id: rec.partner_user_id }}">
                <img :src="rec.twitter_icon" class="img-fluid rounded-circle">
              </router-link>
              </div>
              <p>{{rec.twitter_name}}</p>

            <div v-if="rec.status_n==2" style="display:flex; justify-content: center;">
              <router-link :to="'/myreviewindex?id='+rec.id" style="padding: 5px 15px;border:none; border-radius:3px; width:80%; justify-content: center; width:80%; margin-bottom:1rem;">
             {{rec.status_n | status_fix}}
              </router-link>
            </div>

            <div v-else style="display:flex; justify-content: center;">
              <p style="padding: 5px 15px;border:none; border-radius:3px; width:80%; justify-content: center;">
                 <Icon name="circle" scale="1" :style="rec.status_n | status_style" />{{rec.status_n | status_fix}}
              </p>
            </div>

            <div style="display:flex; justify-content: center;">
              <p  style="border:1px solid #d5d5d5; border-radius:3px; width:80%; justify-content: center;">
                <button class="review-btn" v-if="rec.status_n == 2 || rec.status_n == 7">
                  <router-link :to="{ name: 'ReviewForm', query: { id: rec.id }}">
                    <Icon name="edit" scale="1"/>レビューを書く
                  </router-link>
                </button>
                <button class="review-disabled-btn" v-else>
                  <Icon name="edit" scale="1"/>レビューを書く
                </button>
<!--                 <button class="review-btn" v-if="isChoosable(rec)" @click="submitNoReplied(rec)">
                  <Icon name="burn" scale="1"/>返信なし
                </button> -->
              </p>
            </div>
          </div>
        </div>

      </div>
    <paginate
      :page-count="pageCount"
      :click-handler="clickCallback"
      v-model="currentPage"
      :prev-text="'<<'"
      :next-text="'>>'"
      :containerClass="'pagination'"
      :page-class="'page-item'"
      :page-link-class="'page-link'"
      :prev-class="'page-item'"
      :prev-link-class="'page-link'"
      :next-class="'page-item'"
      :next-link-class="'page-link'"
      >
    </paginate>
    </div>

<Icon name="check-square" scale="1" style="color:" v-show=false />
  </div>
</template>

<style scoped>
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 50%;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn{
    background: rgb(248,70,129);
    background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
  }

  .recommend-card{
    height: auto;
    width: 100%;
    text-align: center;
  }

  .review-btn{
    appearance: none;
    background: #F6F6F6;
    width: 100%;
    padding: 5px 15px;
    border-color: 1px solid d5d5d5 !important;
  }

  .review-disabled-btn{
    appearance: none;
    background: #fff;
    color: #d5d5d5;
    width: 100%;
    padding: 5px 15px;
  }
.pagination {
  text-align: center;
  margin: 10 0 0 0;
  padding: 0;
  list-style-type: none;
  justify-content: center;
}
.pagination .number {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 48px;
  margin: 0 2px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
}
.pagination .number.active {
  background: #F5811F;
}
.pagination .number.active a {
  color: #fff;
}
.pagination .number a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.pagination .number a:focus {
  outline: none;
} 
</style>

<script>

import moment from "moment";
import axios from "../../util/axios";
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import Spinner from 'vue-simple-spinner'
import Paginate from 'vuejs-paginate'
export default {

  name: 'Recommends',
    data() {
      return {
        data_all:"",
        uid: "",
        client: "",
        access_token: "",
        query_params: "",
        isLoading: false,
        pageCount: 1,
        currentPage: 1,
      };
    },
    filters: {
        moment:function(date){
            return moment(date).format('YYYY/MM/DD');
        },
        status_fix:function(data){
          var txt=""
          if(data==0){
            txt="レコメンド"
          } else if(data==1){
            txt="マッチン"
          } else if(data==2){
            txt="投稿したレビューを確認"
          } else if(data==3){
            txt="ノーマッチン"
          } else if(data==4){
            txt="返信なし"
          } else if(data==6){
            txt="保留中"
          } else if(data==7){
            txt="マッチン(返信有)"
          }
          return txt
        },
        status_style:function(data){
          var txt=""
          if(data==0){
            txt="color: rgb(117,83,223);"
          } else if(data==1){
            txt="color: rgb(235,72,114);"
          } else if(data==2){
            txt="color: rgb(179,179,179);"
          } else if(data==3){
            txt="color: rgb(101,101,129);"
          } else if(data==4){
            txt="color: rgb(130,144,229);"
          } else if(data==6){
            txt="color: rgb(70,70,70);"
          } else if(data==7){
            txt="color: rgb(235,72,114);"
          }
          return txt
        }
    },
    methods: {
      isViewable(rec) {
        if (this.query_params != "matching") {
          return [0,1,2,3,4,6,7].includes(rec.status_n)  
        } else {
          return [1,2,4,7].includes(rec.status_n)
        }
        
      },
      isReviwable(rec) {
        const status = rec.status_n

        // if (((status==1) || (status==2)) && rec.is_reviewable){
        if (((status==1) || (status==2)) && true){
          return true
        }else{
          return false
        }

      },
      isChoosable(rec) {
        if(rec.is_choosable && rec.status_n==1) {
          return true
        } else {
          return false
        }
      },
    clickCallback: function(pageNum) {
      this.getAccountData(pageNum);
    },
      async getAccountData(page=1) {
        this.isLoading=true;
        this.currentPage=page;
        const result = await axios.get("/api/recommends"+"?status="+this.$route.query.status+"&page="+page, {
        // const result = await axios.get("/api/recommends", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e
        });
        this.isLoading=false;
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
        this.data_all = result.data;
        this.pageCount = this.data_all.pageCount
      },
      async submitNoReplied(rec){
        this.isLoading = true;
        const result = await axios({
          method : 'POST',
          url    : "/api/recommend/noreplied",
          headers: {'Content-Type': 'application/json',
                  uid: this.uid,
                  "access-token": this.access_token,
                  client: this.client,
                },
          data: {
            recommend:{
              id: rec.id,
            }}
        }).catch((e) => {
          e
          this.$router.push('/login');
        });

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.$router.push('/login');
          this.isLoading = false;
          return;
        }
        if (result) {
          this.$router.push('home');
          return;
        }
        this.isLoading = false;
      },
      
      redirectLogin() {
        //ページ遷移
        this.$router.push("/login");
      },
    },
    async mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.query_params = this.$route.query.status
      this.getAccountData();
      if (this.uid===null){
        this.$router.push('/login');
      }
    },
  
  
  
  components:{
    Icon,
    Spinner,
    Paginate
  }
  }
</script>
