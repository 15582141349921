<template>
  <p></p>
</template>

<style scoped>
</style>

<script>
  export default {
    name: "after_sign_up",
    data: function () {
      return {
        id: null,
        asp_a: null,
      };
    },
    created(){
      this.id = this.$route.query.id;
      (function(){
      var uqid   = "d7214e9cf6fe7f03";
      var gid    = "38";
      var uid   = this.id;
      var uid2   = "";
      var af   = "";
      var pid    = "";
      var amount = "";
      this.asp_a=document.createElement("script");
      this.asp_a.src="//ac.cpa-adapf.com/ac/action_js.php";
      this.asp_a.id="afadaction-"+Date.now();
      this.asp_a.addEventListener("load",function(){(new window.fpcAction(this.asp_a.id)).groupAction(gid, af, [uid, uid2], pid, amount, uqid); console.log('add event.')}.bind(this));
      document.head.appendChild(this.asp_a);
      }.bind(this))();
    },
    bdestroyed(){
      var uqid   = "d7214e9cf6fe7f03";
      var gid    = "38";
      var uid   = this.id;
      var uid2   = "";
      var af   = "";
      var pid    = "";
      var amount = "";
      this.asp_a.removeEventListener("load",function(){(new window.fpcAction(this.asp_a.id)).groupAction(gid, af, [uid, uid2], pid, amount, uqid); console.log('remove event.')}.bind(this),false);
    },
    mounted() {
      this.$router.push({ name: 'Login', query: {send_mail: true }})
    },
    methods: {
    },
  };
</script>