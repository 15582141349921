<template>
  <div class="confirmation">
    <div class="container">
      <div>{{ this.message }}</div>
      <p></p>
      <p></p>
    </div>
  </div>
</template>

<style scoped>
  input{
    margin-top: 30px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }
  select{
    margin-top: 30px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }

  a{
    color: #4B4B4B;
  }
  a:hover{
    color: #4B4B4B;
  }
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  
  .login-btn{
background: rgb(248,70,129);
background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
  }

  .signin-btn{
background: rgb(97,34,219);
background: linear-gradient(90deg, rgba(97,34,219,1) 0%, rgba(91,103,229,1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text{
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text{
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
</style>

<script>
  import axios from "../../util/axios";


  export default {
    name: "user_confirmation",
    data: function () {
      return {
        // uid: "",
        // client: "",
        // access_token: "",
        // email: "",
        // password: "",
        // password_confirmation: "",
        // twitterid: "",
        // sex: "",
        message: "",
        confirmation_token: this.$route.query.confirmation_token,
      };
    },
    mounted() {
      this.confirmation_token = this.$route.query.confirmation_token;
      // this.getAccountData();
      this.confirmation();
      if (this.uid===null){
        this.$router.push('/login');
      }
    },
    methods: {
      async confirmation() {
        // const self = this; 
        const result = await axios
          .get("/api/auth/confirmation"+"?confirmation_token="+this.confirmation_token,
            )
          .catch((e) => {
            e
          });

        if (!result) {
          this.message = "エラーが発生しました";
          return;
        }
        if (!result.data) {
          this.message = "エラーが発生しました";
          return;
        }

        if (result.data) {
          // if (result.data.sex == 2) {
          //   this.$router.push("/after_confirmation?id="+result.data.id)
          // } else {
            this.$router.push("/login");
          // }
        }
      },
    },
  };
</script>