<template>

  <div id="app">
  <Header />
    <div class="page-wrapper">
    <transition>
    <router-view/>
    </transition>
    </div>
    
  </div>
</template>

<script>
import 'normalize.css'
import 'material-design-icons'
import 'vue-loaders/dist/vue-loaders.css';
import Header from '@/components/Header.vue'



export default {
  name: 'App',
  created : function(){
    document.documentElement.setAttribute('lang', 'ja')
  },
  methods : {
    createTitleDesc : function(routeInstance){

      if(routeInstance.meta.title){
          var setTitle = routeInstance.meta.title + ' | 性癖マッチン';
          document.title = setTitle;
      } else {
          document.title = '性癖マッチン'
      }
    },
    redirectLogin() {

      this.$router.push("/login");
    },
  },
   mounted : function(){
      var routeInstance = this.$route;
      this.createTitleDesc(routeInstance);
  },
watch: {
  '$route' (nv, ov) {
    nv
    ov
    scrollTo(0, 0); // トップに移動
    this.$nextTick(() => {
      scrollTo(0, 0); // トップに移動
    })
  }
},
  components :{
    Header,

  },

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
@media (max-width: 480px) {
  .page-wrapper{
    margin-top: 50px;
  }
  .breadcrumb{
    display: none;
  }

  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    min-height: calc(100vh - 50px);
      background: #E7E7F3;
      width: 100%;
  }
}
@media (min-width: 480px) {
  .page-wrapper{
    margin-top: 80px;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    min-height: calc(100vh - 80px);
      background: #E7E7F3;
      width: 100%;
  }
  .breadcrumb{
  }
  
}


a{
  color: #4B4B4B;
  font-size:0.85rem ;
}
a:hover{
  text-decoration: none !important;
}

p{
  color: #4B4B4B;
  font-size:0.85rem;
}
  h2{
    border-left: 5px solid ;
    border-bottom: none;
    border-top: none;
    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 1;
    padding-left: 10px;
    font-size: 1rem !important;
    font-weight: 900 !important;
    color: #4B4B4B;
    text-align: left;
    margin-top: 50px !important;
  }

.content-wrapper{
  margin-top: 50px;
}
.flash{
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(255,255,255,1);
  font-size: 1.4rem;
  /*border-radius: 5px;*/
  padding: 10px;
  border-top: 2px solid #FF8588;
  border-bottom: 2px solid #FF8588;
}
.flash-text{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.v-enter {
  transform: translate(-100px, 0);
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
}
.v-enter-active {
  transition: all .8s 0s ease;
}
.v-leave {
  transform: translate(0, 0);
  opacity: 1;
}
.v-leave-to {
  transform: translate(100px, 0);
  opacity: 0;
}
.v-leave-active {
  transition: all .5s 0s ease;
}

  .v-input--checkbox .v-label {
    font-size: 13px;
  }
  .v-radio .v-label {
    font-size: 13px;
  }
  .v-input__slot .v-label {
    font-size: 13px;
  }

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
