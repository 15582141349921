<template>
  <div class="rankrequest container content-wrapper" style="padding-top:10px;">
  <div v-show="!isLoading">
    <h2>現在のランク</h2>
    <p v-if="rank_req_flag==true">ランク審査中</p>
    <Ranktile :rank="rank" :completedSteps="completedSteps"/>

    <h2>ランク付けの基準</h2>
    <p v-if="rank=='C'"><b>以下を4つ以上満たしていること</b></p>
    <p v-if="rank=='C+'"><b>以下を5つ以上満たしていること</b></p>
    <p v-if="rank=='B'"><b>以下を4つ以上満たしていること</b></p>
    <div class="container white-card left" v-for="rci in rc" :key="rci.dict">
      <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-show="rci.active_flag"/>
        <span v-show="!rci.active_flag"><Icon name="square" scale="1" style="color:gray"/></span> {{rci.dict}}</p>
    </div>
      <button class="login-btn btn-comp" @click="createRankRequest" v-if="db_applicatable && is_rank_request">ランク付け申請する</button>
      <button class="btn-judging btn-comp" v-else-if="rank_req_flag">ただいま審査中</button>
      <Spinner v-show="isLoading2"></Spinner>
      <p>{{message}}</p>
      <p style="margin-bottom:0; margin-top:5px; color:#BE164C;" >※1週間に一度だけ行えます</p>
      <p style="font-size: 0.7rem; color:#BE164C; font-weight:800;" v-show='last_application_date != nil'>申請可能日は{{last_application_date| moment}}</p>
    </div>
    <div v-show="isLoading">
      <Spinner></Spinner>
    </div>
  </div>
</template>
<style scoped>
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  
  .login-btn{
  background: rgb(248,70,129);
  background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
    }

    .btn-judging {
    align-content: center;
    align-items: center;
    background: #d7d7d7;
    border-bottom: 0px solid #805de3;
    border-left: 0px solid #805de3;
    border-radius: 320px;
    border-right: 0px solid #805de3;
    border-top: 0px solid #805de3;
    box-shadow: none;
    flex: none;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    height: 50px;
    justify-content: center;
    width: 350px;
    max-width: 100%;
}
</style>
<script>
import moment from "moment";
import Spinner from 'vue-simple-spinner'

import Ranktile from '@/components/Ranktile.vue'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import axios from "../../util/axios";
const qs = require("qs");
export default {

  name: 'Rankrequest',
  components:{
    Ranktile,
    Icon,
    Spinner
  },
  data: function () {
    return {
      isLoading: true,
      isLoading2: false,
      message: "",
      last_application_date: null,
      applicatable: null,
      db_applicatable: false,
      is_rank_request: true,
      completedSteps: 0,
        uid: "",
        client: "",
        access_token: "",
        email: "",
        twitterid: "",
        rank_req_flag: null,
        info:"",
        info_ts: "",
        payment_conf: "",
        age_conf: "",
        data_all: "",
        rank: "-",
        sex: "",
        rc: "",
    }
  },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData();
      this.completedSteps=0;
      
    },
    filters: {
        moment:function(date){
            return moment(date).format('YYYY/MM/DD HH:mm');
        }
    },
    methods: {
      async getAccountData() {
        const result = await axios.get("/api/account", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin();
          return;
        }
        if (!result.data.email) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin();
          return;
        }
        this.isLoading = false;
        this.email = result.data.email;
        this.twitterid = result.data.twitterid;
        this.info = result.data.notification[0].info;
        this.info_ts = result.data.notification[0].created_at;
        this.payment_conf= result.data.payment_conf;
        this.age_conf = result.data.age_conf; 
        this.data_all = result.data;
        this.sex = result.data.sex;
        this.rank = result.data.rank;
        this.rank_req_flag = result.data.rank_req_flag;
        this.last_application_date = result.data.last_application_date;
        this.rc = result.data.rc;
        this.db_applicatable = result.data.applicatable;
        setTimeout(this.setCompletedSteps, 1000);
        
      },
      setCompletedSteps(){
        this.completedSteps = 4
      },
      async createRankRequest() {
        this.is_rank_request = false;
        this.rank_req_flag = true;
        // const self = this;
        this.isLoading2 = true
        this.message = ""
        const result = await axios
          .post("/api/rankrequest", {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
        }, {
            paramsSerializer: function (params) {
              return qs.stringify(params, { arrayFormat: "brackets" });
            },
          })
          .catch((e) => {
            console.error(e);
          });

        if (!result) {
          this.message = "しばらく経ってから再度申請してください。";
          this.isLoading2 =false;
          return;
        }
        if (!result.data) {
          this.message = "しばらく経ってから再度申請してください。";
          this.isLoading2 =false;
          return;
        }

        if (result.data.state) {
          //結果を基にページ遷移
          // this.$router.push("/home");
          this.message = "申請を受け付けました"
          this.isLoading2 =false;
          return;
        }
      },
    },
  }
</script>
