import { render, staticRenderFns } from "./Recommend.vue?vue&type=template&id=12c762f4&scoped=true&"
var script = {}
import style0 from "./Recommend.vue?vue&type=style&index=0&id=12c762f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12c762f4",
  null
  
)

export default component.exports