<template>
  <div class="Review container">
    <p></p>
    <div class="white-card" style="margin-top:5px; margin-bottom:5px; padding:10px;">
      <p>過去に投稿したレビュー</p>
    </div>

  <transition>
  <div>

    <div class="white-card" style="margin-top:10px;" v-for="rev in reviews" :key="rev.id" >
      <div class="card-wrapper" :ref="rev.id">
      <div class="comment-area">
        <div class="point-wrapper" >
          <p class="point" :id="'post'+rev.id">満足度</p>
          <SvgProgressBar type="rect" :value="rev.point" :options="options"></SvgProgressBar>
        </div>
      <p class="comment">{{rev.form_concrete_review}}</p>
      </div>
       <transition fade>
      <div class="detail-area" v-if="rev.detail_show">
        <div class="detail-area-wrapper">
        <p class="subj">DM対応</p>
        <p class="detail-txt">{{rev.form_dm.text}}</p>
        <p class="subj">第一印象</p>
        <p class="detail-txt">{{rev.form_atomosphere.text}}</p>
        <p class="subj">フォームと実際のギャップ</p>
        <p class="detail-txt">{{rev.form_profile.text}}</p>
        <p class="subj">舌技</p>
        <p class="detail-txt">{{rev.form_tongue.text}}</p>
        <p class="subj">手技</p>
        <p class="detail-txt">{{rev.form_hand.text}}</p>
        <p class="subj">腰使い</p>
        <p class="detail-txt">{{rev.form_waist.text}}</p>
        <p class="subj">後戯</p>
        <p class="detail-txt">{{rev.form_afterplay.text}}</p>
        <p class="subj">前戯時間</p>
        <p class="detail-txt">{{rev.form_foreplay_duration.text}}</p>
        <p class="subj">挿入時間</p>
        <p class="detail-txt">{{rev.form_sex_duration.text}}</p>
        <p class="subj">後戯時間</p>
        <p class="detail-txt">{{rev.form_afterplay_duration.text}}</p>
        <p class="subj">また会いたい？</p>
        <p class="detail-txt">{{rev.form_replay.text}}</p>
        
      </div>
      </div>
    </transition>
      <div class="bottom-area">
        <div class="good-area">
        </div>
        <div class="btn-area">
        <button  v-scroll-to="{ el: '#post'+rev.id, offset: -80 }" @click="toggle(rev)" class="toggle-button"><Icon name="caret-down" scale="1" style="color:gray" class=""/></button>
        </div>
        <div class="ts-area">
          <p>{{rev.updated_at | moment}}</p>
        </div>
      </div>
    </div>
    </div>
    </div>
  </transition>
  </div>
</template>
<style scoped>
p {
  margin: 0;
}
  .subj{
    border-left: 3px solid ;
    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 0 0 0 2;
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 10px;

  }

.point-wrapper{
  display: flex;
  flex-direction: row;
}
.card-wrapper{
  padding: 10px 10px 0 10px;
}
.active{
  background: #775DDF !important;
  color: white !important;
}
.topic-sex-btn{
  background: white;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #775DDF;
  font-weight: 800;
  color: #775DDF;
  position: relative;
}
.subj{
  padding: 3px;
  font-weight: 800;
}
.detail-text{
  padding: 3px;
}
.v-enter-active, .v-leave-active {
  transition: opacity .5s;
}
.good-area{
  padding: 5px;
}
.comment-area{
  text-align: left;
  padding: 5px;
  border-bottom: 1px dotted #d3d3d3;
}
.detail-area{
  text-align: left;
  margin-left: 10px;
}
.point{
  align-self: center;
  margin: 0;
  padding: 5px 5px 5px 0;
}
.comment{
  margin-bottom: 0;
}
.ts-area{
  vertical-align: middle;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px;
  font-size: 0.7rem;
}
.bottom-area {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;

  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
}
.toggle-button {
    align-self: center;
    border: 1px solid gray;
    height: 10px;
    padding: 3px;
    width: 50px;
    height: 30px;
    margin: 3px;
    border-radius: 3px;
}
</style>
<script>
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import moment from "moment";
import axios from "../../util/axios";
// import VueScrollTo from 'vue-scrollto'
import SvgProgressBar from 'svg-progress-bar'

export default {

  name: 'ReviewIndex',
    data() {
      return {
        reviews:[],
        new_flag: true,
        popular_flag: false,
      }
    },
  mounted(){
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData();
      if (this.uid===null){
        this.$router.push('/login');
      }
  },
  components:{
    Icon,
    SvgProgressBar,
    // VueScrollTo
  },
  computed: {
          options: function () {
            return {
            gradientColor:['#755AE7','#FA9E98'],
            text: function (value) {
               return this.htmlifyNumber(value) + '<span style="font-size: 0.4em;">%</span>';
              },

            }
          }
        },
    filters: {
        moment:function(date){
          return moment(date).format('YYYY/MM/DD');
        },
        text_fix:function(rev){
          if(rev.published_text!=null){
            if(rev.detail_show == true){
              return rev.published_text
              // return 'a'
            } else {
              var slicetext = rev.published_text.length > 100 ? (rev.published_text).slice(0,100)+"…" : rev.published_text;
              return slicetext
              // return 'b'
            }
          } else {
            return ""
          }
        }
      },
    methods: {
      async create_like(rev){
        const result = await axios.post("/api/recommend_likes", {
        // const result = await axios.post("/api/recommend_likes", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
          uid: this.uid,
          recommend_id: rev.id
        }).catch((e) => {
          console.error(e);
        });
        rev.liked_count+=1;
        rev.already_liked = true;
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
      },
      async delete_like(rev){
        const result = await axios.delete("/api/recommend_likes", {
        // const result = await axios.delete("/api/recommend_likes", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
          params:{
          uid: this.uid,
          recommend_id: rev.id
          }
        }).catch((e) => {
          console.error(e);
        });
        rev.liked_count-=1;
        rev.already_liked = false;
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
      },
      toggle(rev){
        if (rev.detail_show==false) {
          rev.detail_show=true
        } else {
          rev.detail_show=false
          // const dom = this.$refs[rev.id];
          // const rect = dom.getBoundingClientRect();
          // VueScrollTo.scrollTo(dom)
        }
      },
      async getAccountData() {
        const result = await axios.get("/api/myreviews?id="+this.$route.query.id, {
        // const result = await axios.get("/api/public_review", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (result.data.success==false) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
        this.reviews=result.data
        this.new_flag=true
        this.popular_flag=false
        },
      }

  }



</script>