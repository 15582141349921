<template>
  <div class="PaymentSelect container">
    <transition name="fade">
    <div id="modal" @click="modal_show=false" v-show="modal_show">
      <div class="container">
        <div class="white-card" style="margin-top:200px; padding:30px;">
          <h2>{{plan_name}} 注意事項</h2>
          <div class="text-box" style="text-align: left;">
            <p v-show="plan==1">＊初回決済日を起算日として31日後に2回目の決済、以降30日サイクルの決済となります。</p>
            <p v-show="plan==2">＊初回決済日を起算日として91日後に2回目の決済、以降90日サイクルの決済となります。</p>
            <p v-show="plan==3">＊初回決済日を起算日として181日後に2回目の決済、以降180日サイクルの決済となります。</p>
            <p>＊決済プランの変更は次回決済日前に一度退会し、マイページ決済情報より再度ご希望のプランで決済登録の手続きが必要です。</p>
            <p>＊退会希望の場合は退会ページより必要事項を入力し退会手続きを行ってください。決済会社より退会のメール受信で正式に退会完了となります。</p>
            <p>＊決済の取り消しは行ないません。 <a href="https://service.seiheki-matching.jp/MEN_userpolicy" target="_blank">利用規約第10条</a>をご確認ください。</p>
          </div>
          <a :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307805&SitePass=6qoFpigk&Amount=2500&mail='+uid" v-if="plan==1">
            <button class="btn-rank-request">
              決済サービスへ移動
            </button>
          </a>
          <a :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307806&SitePass=Fbc5xrWe&Amount=6600&mail='+uid" v-if="plan==2">
            <button class="btn-rank-request">
              決済サービスへ移動
            </button>
          </a>
          <a :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307807&SitePass=TSRMPfZE&Amount=12000&mail='+uid" v-if="plan==3">
            <button class="btn-rank-request">
              決済サービスへ移動
            </button>
          </a>
        </div>
        </div>

    </div>
    </transition>
    <h2>プラン選択</h2>
      <div class="alert-wrapper"><p class="alert-icon"><Icon name="exclamation-triangle" scale="2" style="padding:3px; margin:auto; top:0;bottom:0; color:red;"/></p>
        <p class="alert">決済情報確認の為、性癖マッチンにご登録されたメールアドレスで決済登録を行ってください。</p></div>
      <div class="row">
        <div class="col-md-12">
          <div class="white-card" @click="plan=1; modal_show=true;">
            <p>1ヶ月プラン</p>
            
            <p class="amount">¥<span class="amount-value">2,500</span></p>

          </div>
        </div>
        <div class="col-md-6">
          <div class="white-card" @click="plan=2; modal_show=true;">
            <p>3ヶ月プラン</p>
            
            <p class="amount">¥<span class="amount-value">6,600</span></p>
            <p>１ヶ月あたり2,200円</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="white-card" @click="plan=3; modal_show=true;">
            <p>6ヶ月プラン</p>
            
            <p class="amount">¥<span class="amount-value">12,000</span></p>
            <p>１ヶ月あたり2,000円</p>
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>
  h2{
    margin-top: 10px !important;
  }
  .alert{
    color: red;
    text-align: left;
    margin: auto;
    margin-left: 0;
    top: 0;
    bottom: 0;
    padding-left: 0px;
  }
  .alert-icon{
    vertical-align: middle;
    display: flex;
    padding: 15px;
    margin: 0;

  }
  .alert-wrapper{
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }
  .amount{
    font-weight: 900;
    font-size: 2.5rem;
    background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
    -webkit-background-clip: text;
    color: transparent;
  }
  .amount-value{
    font-size: 3rem;
  }
  
  #modal{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0,0,0,0.5);
    z-index: 100;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .15s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn-rank-request{
    background: linear-gradient(#70708c, #5b577a);
    border-radius: 320px;
    color: #ffffff;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 900;
    height: auto;
    line-height: 1.4;
    padding: 10px 15px 10px 15px;
    text-align: center;
    width: auto;
    max-width: 100%;
    justify-content: center;
}
</style>
<script>
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'

export default {

  name: 'PaymentSelect',
  components: {
    Icon
  },
  data() {
    return{
      uid:null,
      client:null,
      access_token:null,
      plan:0,
      modal_show:false,
    }
  },
  computed:{
    plan_name: function(){
      if(this.plan==1){
        return "1ヶ月プラン"
      }else if(this.plan==2){
        return "3ヶ月プラン"
      }else if(this.plan==3){
        return "6ヶ月プラン"
      }else{
        return ""
      }
    }
  },
  mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      if (this.uid===null){
        this.$router.push('/login');
      }
  }
  }
</script>