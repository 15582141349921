<template>
  <div class="recommends container content-wrapper" style="padding-top:10px;">

    <div class="container">

<form action="https://mc-credit.com.sg/service/continue/cancel.htm" method=”post”>
<input type="hidden" name="SiteId" :value="site_id">
<input type="hidden" name="SitePass" :value="site_pass">

<div style="text-align:left">
  <h2>決済退会手続き</h2>

  <label>お客様ID(決済用)</label>
  <div class="form-group">
    <input type="text-field" name="CustomerId" :value="payment_id" class="form-control">
  </div>
  
  <label>お客様パスワード(決済用)</label>
  <div class="form-group">
    <input type="text-field" name="CustomerPass" :value="payment_pass" class="form-control">
  </div>
</div>

    <input type="submit" class="btn-comp login-btn">
    </form>
    </div>
  </div>
</template>

<style scoped>
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  
  .login-btn{
background: rgb(248,70,129);
background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
  }
  label{
    border-left: 5px solid ;
    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 30px;
  }
</style>

<script>
  import axios from "../../util/axios";

  export default {
    name: "PaymentCancellation",
  components:{
  },
    data() {
      return {

        isLoading: true,
        completedSteps: 0,
        
        uid: "",
        client: "",
        access_token: "",
        email: "",
        twitterid: "",
        info:"",
        info_ts: "",
        payment_conf: "",
        payment_kind: 0,
        age_conf: "",
        data_all: "",
        rank: "-",
        sex: "",
        matching_count: 0,
        recommend_count: 0,
        site_id: "32307803",
        site_pass: "JRjF6Nnk",
        payment_id: "",
        payment_pass: "",
      };
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData();
      if (this.uid===null){
        this.$router.push('/login');
      }
    },
    methods: {
      async getAccountData() {
        const result = await axios.get("/api/payment_information", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
        this.payment_id = result.data.payment_id;
        this.payment_pass = result.data.payment_pass;
        this.payment_conf= result.data.payment_conf;
        this.payment_kind= result.data.payment_kind;
        if(this.payment_kind==2500){
          this.site_id = "32307805"
          this.site_pass = "6qoFpigk"
        } else if(this.payment_kind==6600){
          this.site_id = "32307806"
          this.site_pass = "Fbc5xrWe"
        } else if(this.payment_kind==12000){
          this.site_id = "32307807"
          this.site_pass = "TSRMPfZE"
        }
        this.email = result.data.email;
        if (this.matching_count==null){
          this.matching_count = 0;
        }
        if (this.recommend_count == null){
          this.recommend_count = 0;
        }
        this.isLoading = false;
        setTimeout(this.setCompletedSteps, 1000);
      },
    }
  }
</script>