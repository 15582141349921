import Vue from "vue";
import VueRouter from "vue-router";
import VueHead from 'vue-head'
import index from "../views/Index.vue";
import login from "../views/Login.vue";
import signup from "../views/SignUp.vue";
import forget_password from "../views/ForgetPassword.vue";
import password_reset from  "../views/PasswordReset.vue";
import home from "../views/Home.vue";
import form from "../views/Form.vue";
import femaleform from "../views/FemaleForm.vue";
import paymentinformation from "../views/Paymentinformation.vue";
import preview from "../views/Preview.vue";
import profile from "../views/Profile.vue";
import review from "../views/Review.vue";
import reviewform from "../views/ReviewForm.vue";
import reviewindex from "../views/ReviewIndex.vue";
import myreviewindex from "../views/MyReviewIndex.vue";
import topics from "../views/Topics.vue";
import topic from "../views/Topic.vue";
import age_confirmation from "../views/AgeConfirmation.vue"
import recommends from "../views/Recommends.vue";
import recommend from "../views/Recommend.vue";
import noticeindex from "../views/NoticeIndex.vue";
import notice from "../views/Notice.vue";
import user_confirmation from "../views/UserConfirmation.vue";
import after_sign_up from "../views/AfterSignUp.vue";
import change_card from "../views/ChangeCard.vue";
import payment_select from "../views/PaymentSelect.vue";
import payment_cancellation from "../views/PaymentCancellation.vue";
import rankrequest from "../views/Rankrequest.vue";
import qa from "../views/QA.vue";
import conditions_setting from "../views/ConditionsSetting.vue";
import tutorial from "../views/Tutorial.vue";

Vue.use(VueHead)
Vue.use(VueRouter);

const routes = [
  {
    path: "/payment_cancellation",
    name: "PaymentCancellation",
    component: payment_cancellation,
    meta: {title: '継続課金解約手続き', desc: ''}
  },
  {
    path: "/conditionsform",
    name: "ConditionsSetting",
    component: conditions_setting,
    meta: {title: '条件設定', desc: ''}
  },
  {
    path: "/change_card",
    name: "ChangeCard",
    component: change_card,
    meta: {title: 'カード情報の変更', desc: ''}
  },
  {
    path: "/",
    name: "Index",
    component: index,
    meta: { title: '性癖マッチン', desc: '' }
  },
  {
    path: "/login", //sign_in
    name: "Login",
    component: login,
    meta: { title: 'ログイン', desc: '' }
  },
  {
    path: "/signup", //sign_up
    name: "SignUp",
    component: signup,
    meta: { title: '新規登録', desc: '' }
  },
  {
    path: "/age_confirmation", //sign_up
    name: "AgeConfirmation",
    component: age_confirmation,
    meta: { title: '年齢確認書類提出', desc: '' }
  },
  {
    path: "/user_confirmation",
    name: "Confirmation",
    component: user_confirmation,
    meta: { title: 'ユーザー認証', desc: ''}
  },
  {
    path: "/after_sign_up",
    name: "AfterSignUp",
    component: after_sign_up,
    meta: { title: 'ユーザー認証', desc: ''}
  },
  {
    path: "/forget_password",
    name: "ForgetPassword",
    component: forget_password,
    meta: { title: 'パスワード再設定', desc: '' }
  },
  {
    path: "/password_reset",
    name: "PasswordReset",
    component: password_reset,
    meta: { title: 'パスワード再設定', desc: '' }
  },
  {
    path: "/home",  //mypage
    name: "Home",
    component: home,
    meta: { title: 'マイページ', desc: '' }
  },
  {
    path: "/form",  //mypage
    name: "Form",
    component: form,
    meta: { title: 'プロフィール', desc: '' }
  },
  {
    path: "/reviewform",  //mypage
    name: "ReviewForm",
    component: reviewform,
    meta: { title: 'レビュー', desc: '' }
  },
  {
    path: "/reviewindex",  //mypage
    name: "ReviewIndex",
    component: reviewindex,
    meta: { title: '投稿したレビュー一覧', desc: '' }
  },
  {
    path: "/myreviewindex",  //mypage
    name: "MyReviewIndex",
    component: myreviewindex,
    meta: { title: '投稿したレビュー一覧', desc: '' }
  },
  {
    path: "/femaleform",  //mypage
    name: "FemaleForm",
    component: femaleform,
    meta: { title: 'プロフィール', desc: '' }
  },
  //profile
  {
    path: "/profile",  //mypage
    name: "Profile",
    component: profile,
    meta: { title: 'プロフィール', desc: '' }
  },
    //noticeindex
  {
    path: "/notice/index",  //mypage
    name: "NoticeIndex",
    component: noticeindex,
    meta: { title: 'お知らせ一覧', desc: '' }
  },
  //notice
  {
    path: "/notice/:id",  //mypage
    name: "Notice",
    component: notice,
    meta: { title: 'お知らせ', desc: '' }
  },
  //preview
  {
    path: "/preview",  //mypage
    name: "Preview",
    component: preview,
    meta: { title: 'プレビュー', desc: '' }
  },
  //rank_request
  {
    path: "/rankrequest",
    name: "Rankrequest",
    component:rankrequest,
    meta:{ title: 'ランク付け依頼', desc: '' }
  },
  //payment_information
  {
    path: "/payment_information",  //mypage
    name: "Paymentinformation",
    component: paymentinformation,
    meta: { title: '決済情報', desc: '' }
  },
  //review
  {
    path: "/review",  //mypage
    name: "Review",
    component: review,
    meta: { title: 'みんなのレビュー', desc: '' }
  },
  //topics
  {
    path: "/topics",  //mypage
    name: "Topics",
    component: topics,
    meta: { title: '掲示板', desc: '' }
  },
  //topic #id
  {
    path: "/topic",  //mypage
    name: "Topic",
    component: topic,
    meta: { title: '掲示板', desc: '' }
  },
  //recommends #id
  {
    path: "/recommends",  //mypage
    name: "Recommends",
    component: recommends,
    meta: { title: 'レコメンド一覧', desc: '' }
  },
  {
    path: "/recommend",  //mypage
    name: "Recommend",
    component: recommend,
    meta: { title: 'レコメンド', desc: '' }
  },
  {
    path: "/payment_select",
    name: "PaymentSelect",
    component: payment_select,
    meta: { title: 'プラン選択', desc: '' }
  },

  // QA
  {
    path: "/qa",  //mypage
    name: "QA",
    component: qa,
    meta: { title: 'QA', desc: '' }
  },

  {
    path: "/tutorial",
    name: "Tutorial",
    component: tutorial,
    meta: { title: 'チュートリアル', desc: '' }
  },


];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        top: 0
      }
    } else {
      return {
        top: 0
      }
    }
  },
});

export default router;