<template>
  <a v-on:click="logout()">Logout</a>
</template>

<script>
  import axios from "../../util/axios";

  export default {
    name: "logout",
    data(){
    return {
        uid: "",
        client: "",
        access_token: "",
    }
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
    },
    methods: {
      async logout() {
          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
        // const self = this;
        const result = await axios.delete("/api/auth/sign_out",{
          // test: { test: "test" },
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          this.message = "エラー";
          this.$router.push("/login");
          return;
        }
        if (!result) {
          this.message = "エラー";
          this.$router.push("/login");
          return;
        }

        if (result) {
          //結果を基にページ遷移
          localStorage.setItem('sex', 4)
          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
          localStorage.removeItem('sex')
          this.$router.push("/login");
        }
      },
    },
  };
</script>